<template>
  <page fluid="lg" title="Editar medición">
    <formulario :medicion="medicion" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiMediciones from "@/services/mediciones.js";

export default {
  components: { Formulario },
  data() {
    return {
      medicion: {},
      urlAceptar: "",
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiMediciones.cargar(id);
      this.medicion = resultado;
    },
    async actualizar() {
      let ok = await apiMediciones.actualizar(this.medicion);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado la medición");
        this.$router.push({
          path: this.$route.query.redirect || "..",
        });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
